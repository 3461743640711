<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="propositionsFiltered"
        item-key="id"
        :items-per-page.sync="options.itemsPerPage"
        :options.sync="options"
        disable-sort
        mobile-breakpoint="0"
        :footer-props="{
            itemsPerPageOptions: []
          }"
        hide-default-footer
        style="backgroud-color: transparent;">
      <template v-slot:header.channel="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.Influencer.account="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.responsedAt="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.manuStatus="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.maxEditCount="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.request="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.reviewStatus="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.reviewRegistedAt="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:header.reviewRate="{header}">
        <span style="font-size:10px;font-weight:normal">{{header.text}}</span>
      </template>
      <template v-slot:top>
        <v-row>
          <div class="pa-4" style="font-size:16px;color: #484848;">
            신청내역 <span class="body-2 ml-2" style="color: #808080">{{propositionsFiltered.length}}개 ・ {{Math.floor((parseInt(propositionsFiltered.length)-1) / options.itemsPerPage + 1)}} Pages</span>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="#F5F5F5" v-bind="attrs" v-on="on" style="color:#484848;font-size:13px">
                  {{options.itemsPerPage}}명씩 보기
                  <v-icon small>expand_more</v-icon>
                </v-chip>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in [20, 50, 100]" :key="index" @click="options.itemsPerPage = item">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.channel="props">
        <naverIcon v-if="props.item.taggableType === 'naver'"/>
        <instaIcon v-if="props.item.taggableType === 'insta'"/>
        <youtubeIcon v-if="props.item.taggableType === 'youtube'"/>
      </template>
      <template v-slot:item.Influencer.account="{item}">
        <v-row @click="influencerDetailDlg(item.Influencer)">
          <v-col cols="3">
            <v-avatar size="40">
              <img v-if="item.Influencer.photo" :src="item.Influencer.photo"/>
              <img v-else src="@/assets/img/matching-placeholder.jpg">
            </v-avatar>
          </v-col>
          <v-col cols="8" class="ml-1">
            <v-row class="caption mt-3" style="color:#4770BD">
              {{item.Influencer.account}}
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.responsedAt="props">
        <div v-if="props.item.responsedAt" class="caption" style="color:#808080">
          {{$moment(props.item.responsedAt).tz('Asia/Seoul').format('YYYY.MM.DD')}}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.manuStatus="{item}">
        <div class="caption" style="color:#808080">
          <div v-if="(item.status || 0) === 10">
            혜택 발송 필요
          </div>
          <div v-else-if="(item.status || 0) === 12">
            매장 방문 예정
          </div>
          <div v-else-if="(item.status || 0) === 20">
            원고 등록 예정
          </div>
          <div v-else-if="(item.status || 0) > 21 && (item.status || 0) < 50 && manuscript[item.id]">
            <a v-if="manuscript[item.id] && manuscript[item.id][0].status === 3"
               @click="manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step)"
               style="text-decoration: underline">
              등록
            </a>
            <a v-else-if="manuscript[item.id] && manuscript[item.id][0].status === 1"
               @click="manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step)"
               style="text-decoration: underline">
              원고 수정 중
            </a>
            <a v-else-if="manuscript[item.id] && manuscript[item.id][0].status === 2"
               @click="manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step)"
               style="text-decoration: underline">
              리뷰 등록 예정
            </a>
            <div v-else-if="manuscript[item.id] && manuscript[item.id][0].status === 4">
              링크 재등록 요청
            </div>
            <a v-else-if="manuscript[item.id]"
               @click="manuscriptDialog(manuscript[item.id], manuscript[item.id][0].step)"
               style="text-decoration: underline">
              {{manuscript[item.id][0].step ? (manuscript[item.id][0].step + '차 수정본') : '초본 등록'}}
            </a>
          </div>
          <div v-else>
            -
          </div>
        </div>
      </template>
      <template v-slot:item.maxEditCount="{item}">
        <div class="caption" style="color:#808080">
          <div v-if="item.maxEditCount">
            {{(item.maxEditCount - ((manuscript[item.id] || [{step: 0}])[0].step + (((manuscript[item.id] || [{step: 0}])[0].status == 2) ? 1 : 0)))}}/{{item.maxEditCount}}회
          </div>
          <div v-else>
            -
          </div>
        </div>
      </template>
      <template v-slot:item.request="{item}">
        <v-btn v-if="manuscript[item.id] && _.orderBy(manuscript[item.id], ['step'], ['desc'])[0].status === 0"
               rounded depressed small color="rouge" class="white--text"
               @click="manuscriptReqDialog(manuscript[item.id], manuscript[item.id][0].step)">요청</v-btn>
        <v-btn v-else-if="manuscript[item.id] && _.orderBy(manuscript[item.id], ['step'], ['desc'])[0].status === 1"
               rounded depressed small outlined color="rouge"
               @click="manuscriptReqDialog(manuscript[item.id], manuscript[item.id][0].step)">보기</v-btn>
        <v-btn v-else rounded depressed disabled small>요청</v-btn>
      </template>
      <template v-slot:item.reviewStatus="{item}">
        <div class="caption" style="color:#808080">
          <div v-if="(item.status || 0) === 30">
            대기
          </div>
          <div v-else-if="(item.status || 0) === 32 || (item.status || 0) === 40">
            <a :href="item.review" target="_blank" style="text-decoration: underline">
              등록
            </a>
          </div>
          <div v-else>
            -
          </div>
        </div>
      </template>
      <template v-slot:item.reviewRegisteredAt="props">
        <div v-if="props.item.reviewRegisteredAt" class="caption" style="color:#808080">
          {{$moment(props.item.reviewRegisteredAt).tz('Asia/Seoul').format('YYYY.MM.DD')}}
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template v-slot:item.reviewRate="{item}">
        <div v-if="item.rating" class="caption" style="color:#808080">
          {{item.rating + '/' + 5}}
        </div>
        <div v-else-if="item.review"
             @click="openRateDialog=true;rateProposition=item"
             class="caption rouge--text" style="text-decoration: underline;">
          평가하기
        </div>
        <div v-else>
          -
        </div>
      </template>
    </v-data-table>
      <div class="text-center" style="background-color: white">
        <v-pagination
            v-model="options.page"
            :length="Math.floor((parseInt(propositionsFiltered.length)-1) / options.itemsPerPage + 1)"
            :total-visible="10"
        ></v-pagination>
      </div>
    </div>
    <v-row justify="center" align="center">
      <v-dialog v-model="openManuscriptDialog" content-class="white-two" max-width="500px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openManuscriptDialog=false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="subheading" style="color: #484848;">
              등록된 원고 이미지/영상
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="body-1 mb-2" style="color: #757575">
              썸네일을 클릭하시면 원본 미디어를 보실 수 있습니다.
            </v-row>
            <v-row>
              <v-col style="display: flex; overflow-x: auto;">
                <v-img v-for="(img, index) in currentManuscript.media" :key="index" class="mr-4"
                       :src="img" width="240" height="240"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn tile outlined color="rouge" @click="openManuscriptDialog=false;openManuscriptReqDialog=true">수정/업로드 요청</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile class="rouge white-two--text" @click="openManuscriptDialog=false">닫기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openManuscriptReqDialog" content-class="white-two" max-width="500px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openManuscriptReqDialog=false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="pb-2">
            <v-row class="subheading" style="color: #484848;">
              원고 열람하기 (첫 등록 원고)
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="body-1 mb-2">
              <v-col cols="12">
                <v-btn rounded flat color="rouge white--text" style="font-size:13px"
                       :href="currentManuscript.manuscript" target="_blank">원고 보기</v-btn>
              </v-col>
              <v-col class="text-decoration-underline rouge--text caption" @click="openManuscriptReqDialog=false;openManuscriptReReqDialog=true">잘못된 원고 링크가 등록되었나요?</v-col>
            </v-row>
            <v-row class="subheading mt-4" style="color: #484848;">
              {{currentManuscriptStatus == 0 ? '요청 보내기' : '보낸 요청'}}
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              {{currentManuscriptStatus == 0 ? '요청 사항을 적어주시면 인플루언서가 확인 후 리뷰를 업로드합니다.' : '인플루언서가 요청을 확인중입니다.'}}
            </v-row>
            <v-row class="my-2">
              <v-radio-group v-if="currentManuscriptStatus === 0" v-model="currentManuscript.status" row :mandatory="true" class="radio_group" :disabled="currentManuscriptStatus != 0" hide-details>
                <v-radio v-if="enableRequest" label="수정 요청" value="1" class="radio pl-2" color="rouge"></v-radio>
                <v-radio :label="enableRequest ? '업로드 요청' : '수정 및 업로드 요청'" value="2" class="radio pl-2" color="rouge"></v-radio>
              </v-radio-group>
              <span v-else style="color:#757575; font-size:14px; font-weight:700">{{currentManuscriptStatus === 1 ? '수정 요청 내용' : '업로드 요청 내용'}}</span>
            </v-row>
            <v-row>
              <v-textarea v-if="currentManuscript.status == 1" v-model="currentManuscript.request" :readonly="currentManuscriptStatus != 0"
                          name="request" type="request"
                          v-validate="'required|min:10'"
                          :rules="[errors.first('request') || !errors.has('request')]"
                          outlined></v-textarea>
              <v-textarea v-else v-model="currentManuscript.request" :readonly="currentManuscriptStatus != 0" outlined></v-textarea>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openManuscriptReqDialog=false">닫기</v-btn>
            <v-btn tile class="rouge white-two--text" :disabled="currentManuscriptStatus != 0" @click="manuscriptRequest()">요청 발송</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openManuscriptReReqDialog" content-class="white-two" max-width="500px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openManuscriptReReqDialog=false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text class="pb-2">
            <v-row class="subheading" style="color: #484848;">
              원고 재등록 요청
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row class="subheading mt-4" style="color: #484848;">
              요청 사유
            </v-row>
            <v-row>
              <v-select v-model="currentManuscript.reRequest"
                        :items="[
                        '원고 열람 권한이 없습니다.',
                        '원고 내용이 비어있습니다.',
                        '다른 업체의 원고가 등록되었습니다.',
                        '존재하지 않는 페이지입니다.'
                      ]"
                        class="pa-0 my-2"
                        hide-details
                        outlined
                        flat></v-select>
            </v-row>
            <v-row style="color: #757575">
              원고 링크가 잘못 등록된 경우에만 재등록 요청이 가능합니다. 사유가 위 목록에 없는 경우 고객센터로 연락해주세요.
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openManuscriptReReqDialog=false">닫기</v-btn>
            <v-btn tile class="rouge white-two--text" @click="manuscriptReRequest()">요청 발송</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center">
      <v-dialog v-model="openRateDialog" content-class="white-two" max-width="400px">
        <v-card class="px-4">
          <v-card-title class="pb-0">
            <div>
              <v-icon color="#757575" @click="openRateDialog=false">close</v-icon>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="subheading" style="color: #484848;">
              리뷰 평가하기
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col class="review-stars">
                <v-icon v-for="(val, idx) in isActive" @mouseover="mouseOver(idx)" :key="idx">{{val ? 'mdi-star' : 'mdi-star-outline'}}</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn tile outlined color="rouge" @click="openRateDialog=false">취소</v-btn>
            <v-btn tile class="rouge white-two--text" @click="rate()">평가하기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <InfluencerDetail v-model="openInfluencerDetail" :openInfluencer="openInfluencer"></InfluencerDetail>
    </v-row>
  </div>
</template>

<script>
  import InfluencerDetail from "@/components/campaign/dialog/InfluencerDetail";

  export default {
    name: 'Propositiondetails',
    props: ['campaign', 'propositions'],
    components: {
      naverIcon: () => import('@/assets/img/campaign/naver.svg'),
      instaIcon: () => import('@/assets/img/campaign/insta.svg'),
      youtubeIcon: () => import('@/assets/img/campaign/youtube.svg'),
      InfluencerDetail
    },
    data() {
      return {
        headers_desktop: [
          { text: '채널', value: 'channel' },
          { text: '채널 정보', value: 'Influencer.account', width: '200px' },
          { text: '수락 일자', value: 'responsedAt'},
          { text: '원고 등록 상태', value: 'manuStatus' },
          { text: '남은 수정 횟수', value: 'maxEditCount' },
          { text: '수정∙업로드 요청', value: 'request' },
          { text: '리뷰 등록 상태', value: 'reviewStatus' },
          { text: '리뷰 등록 일자', value: 'reviewRegisteredAt' },
          { text: '리뷰 평가', value: 'reviewRate' }
        ],
        headers_mobile: [
          { text: '채널', value: 'channel' },
          { text: '채널 정보', value: 'Influencer.account', width: '200px' },
          { text: '수락 일자', value: 'responsedAt'}
        ],
        options: {page: 1, itemsPerPage: 20},
        manuscript: {},
        openManuscriptDialog: false,
        openManuscriptReqDialog: false,
        openManuscriptReReqDialog: false,
        currentManuscript: {},
        enableRequest: true,
        openRateDialog: false,
        isActive: [false, false, false, false, false],
        rateProposition: {},
        openInfluencer: {},
        openInfluencerDetail: false,
        currentManuscriptStatus: 0
      }
    },
    computed: {
      headers() {
        // return this.headers_desktop;
        return this.headers_desktop;
      },
      propositionsFiltered() {
        let filtered = this.propositions.filter((item) => (item.status === 2 || (item.status >= 10 && item.status < 50)));
        return this._.orderBy(filtered, ['responsedAt'], ['desc']);
      }
    },
    watch: {
      propositions(value) {
        this.getManuscripts(value);
      }
    },
    methods: {
      manuscriptDialog(manuscripts, step) {
        // this.openManuscriptReqDialog=false;
        // this.openManuscriptDialog = true;
        this.openManuscriptDialog = false;
        this.openManuscriptReqDialog=true;
        this.currentManuscript = JSON.parse(JSON.stringify(manuscripts.filter((manuscript) => (manuscript.step === step))[0]));
        this.currentManuscriptStatus = this.currentManuscript.status;
        this.enableRequest = step < (this._.find(this.propositions, ['id', this.currentManuscript.propositionId]).maxEditCount - 1);
      },
      manuscriptReqDialog(manuscripts, step) {
        this.openManuscriptDialog = false;
        this.openManuscriptReqDialog=true;
        this.currentManuscript = JSON.parse(JSON.stringify(manuscripts.filter((manuscript) => (manuscript.step === step))[0]));
        this.currentManuscriptStatus = this.currentManuscript.status;
        this.enableRequest = step < (this._.find(this.propositions, ['id', this.currentManuscript.propositionId]).maxEditCount - 1);
      },
      async manuscriptRequest() {
        let success = await this.$validator.validateAll();
        if (success) {
          this.openManuscriptReqDialog = false;
          await this.axios.put('/proposition/manuscript', {
            id: this.currentManuscript.id,
            step: this.currentManuscript.step,
            status: this.currentManuscript.status,
            request: this.currentManuscript.request,
            editRequestedAt: new Date()
          });

          await this.axios.put('/campaign/proposition?id=' + this.currentManuscript.propositionId, {
            status: (this.currentManuscript.status == 2) ? 30 : 22
          });

          this.$emit('update:proposition');
        }
      },
      async manuscriptReRequest() {
        this.openManuscriptReReqDialog = false;
        await this.axios.put('/proposition/manuscript', {
          id: this.currentManuscript.id,
          status: 4,
          reRequest: this.currentManuscript.reRequest,
          reRequestedAt: new Date()
        });

        await this.axios.put('/campaign/proposition?id=' + this.currentManuscript.propositionId, {
          status: 22
        });
      },
      mouseOver(idx) {
        this.isActive.forEach((value, n) => {
          if (n > idx) {
            this.$set(this.isActive, n, false)
          } else {
            this.$set(this.isActive, n, true)
          }
        });
      },
      rate() {
        let star = 0;
        this.openRateDialog=false;
        this.isActive.forEach((value, n) => {
          if (value) star++;
          this.$set(this.isActive, n, false)
        });
        this.axios.put('/campaign/proposition?id=' + this.rateProposition.id, {
          status: 40,
          rating: star,
          ratedAt: new Date()
        });
      },
      getManuscripts(value) {
        let query = '';
        value.map((proposition, idx) => {
          query += 'propositionId=' + proposition.id;
          if (idx !== value.length - 1) {
            query += ' OR '
          }
        });

        this.axios.get('/proposition/manuscript?orderBy=step&direction=desc&query=' + encodeURIComponent(query)).then(scripts => {
          this.manuscript = this._.groupBy(scripts.data.manuscripts, 'propositionId');
        }).catch((err) => {
          console.log(err)
        });
      },
      async influencerDetailDlg(item, event) {
        console.log(event)
        this.openInfluencerDetail = true;
        this.openInfluencer = item;
      }
    },
    async mounted() {
      this.getManuscripts(this.propositions);
    }
  };
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/custom';
.radio_group {
  margin-top: 0px;
}

.radio {
  border: 1px solid;
  border-radius: 8px;
  max-width: 150px;
  height: 40px;
  margin-top: 0px;
  width: 150px;
}

.radio.v-item--active {
  border-color: var(--rouge-color);
}

.review-stars {
  padding:0;
  text-align:center;
}
.review-stars i {
  cursor:pointer;
  color:#FCCC22;
  font-size:34px;
}

.review-direction {
  font-size:14px;
}

@media(max-width:768px) {
  .review-direction {
    font-size:14px;
  }
  .review-stars {
    padding: 10px 0;
  }
}
</style>
